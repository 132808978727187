<template>
  <div class="exercise-preview-modal">
    <img src="@/assets/ic_nav_close_white.svg" @click="showModal = false; $router.go(-1)">
    <video-player v-if="videoOptions" @playerEnded="closePreview" :options="videoOptions"/>
    <div v-html="exercise.hints"></div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'

import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
    name: 'ExercisePreview',
    data() {
        return {
        exercise: {},
        videoOptions: null,
        }
    },
    methods: {
        init(){
            let exercises = this.$store.getters.getExercises;
            let exerciseId = this.$route.params.exerciseId;
            this.exercise = exercises.find(ex => ex.id == exerciseId);
            if (!this.exercise) { return; }
            this.videoOptions = 
                {
                autoplay: true,
                muted: true,
                controls: true,
                fluid: true,
                nativeControlsForTouch: false,
                sources: [
                    {
                    src: "https://media.sportlerplus.com/loops/" + this.exercise.video_file,
                    type: "video/mp4"
                    }
                ]
            };
        
        },
        closePreview() {
            this.$router.go(-1);
        }
    },
    components: {
        VideoPlayer
    },
    computed: {
    },
    mounted() {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.rotationMessageHandler) {
            window.webkit.messageHandlers.rotationMessageHandler.postMessage({orientation: "portrait"})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.requestRotation("portrait");
        }
        this.init();
    },
    beforeDestroy() {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.rotationMessageHandler) {
            window.webkit.messageHandlers.rotationMessageHandler.postMessage({orientation: "all"})
        }
        else if (window.AndroidJSInterface != undefined) {
            window.AndroidJSInterface.unlockRotation();
        }
    }
}
</script>

<style lang="scss">
  .exercise-preview-modal {
    background: $colorWhite;
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: auto 16px;
    border-radius: 8px;
    overflow: hidden;

    @media screen and (min-width: 512px) {
        margin: auto;
    }

    img {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      padding: 12px;
    }

    video {
        border-radius: 8px 8px 0 0;
        height: auto !important;
    }

    p {
        margin-bottom: 0;
    }

    ul {
        padding: 0 12px 0 20px !important;
        margin: 14px 0 16px;

        li {
            padding: 8px 0 8px 36px;
            position: relative;
            list-style-type: none;
            font-size: 14px;
            letter-spacing: -0.2px;
            font-weight: 600;
            line-height: 20px;
            color: $colorBlack;

            &:before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                width: 24px;
                height: 28px;
                background: url('../assets/ic_premium_check.svg')
            }
        }
    }
  }
</style>
