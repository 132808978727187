<template>
    <div>
        <video ref="videoPlayer" class="video-js" playsinline></video>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options);
        this.$emit("sendPlayerRef", this.player);
        this.player.on('ended', () => this.$emit("playerEnded"));
        this.player.on('timeupdate', () => this.$emit("timeUpdate", this.player.currentTime()));
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>

<style lang="scss">
    video {
        @media screen and (orientation: landscape) {
            left: 50% !important;
            height: 100vh !important;
            transform: translateX(-50%) !important;
        }
    }
</style>